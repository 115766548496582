<template>
  <div>
    <div style="padding-bottom: 15px; display: flex;">

      <el-form ref="searchForm" class="form-search" :model="searchForm" inline>
        <el-form-item>
          <el-input v-model="searchForm.keyword_meta" placeholder="元数据" clearable style="width: 200px;" @keyup.enter.native="refresh" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.keyword_meta_model" placeholder="元模型" clearable style="width: 200px;" @keyup.enter.native="refresh" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="refresh">搜索</el-button>
        </el-form-item>
      </el-form>
    
    </div>


    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column type="selection" width="39" />
      <el-table-column prop="code" label="元数据编号" width="180" />
      <el-table-column prop="name" label="元数据名称" min-width="180" show-overflow-tooltip />
      <el-table-column label="元数据类型" width="180">
        <template slot-scope="{row}">
          <span v-if="row.model_id">{{row.model_name}}({{row.model_code}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180" />
      <el-table-column prop="update_time" label="更新时间" width="180" />
      <el-table-column label="操作" width="160">
        <template slot-scope="{row}">
          <el-button @click="$router.push({ path: '/page-2-6', query: { type: 'meta', id: row.id}})" type="text" size="small">定位到元数据</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      tableSort: { prop: 'id', order: 'descending' },

      searchForm: {},

      data: [
        { label: '元数据稽核', children: [
          { label: 'XXX系统MYSQL库', children: [
            { label: '对私客户基本信息表', type: 1 },
            { label: 'product', type: 1 }
          ]  },
          { label: 'YYY系统MYSQL库' },

          { label: 'ZZZ系统DB2库' },
          { label: 'WWW系统GP库' },
        ]}
      ],
      currentNode: null,
    }
  },
  created() {
    // this.refresh();
  },
  methods: {
    async refresh() {
      console.log('refresh')
      try {
        this.loading = true;
        const res = await this.$api.execute('meta.list', _.assign({}, this.searchForm, _.pick(this, ['current', 'pageSize', 'tableSort'])));
        this.tableData = res.list;
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },
    handleNodeClick(e) {
      this.currentNode = e;
      console.log('handleNodeClick', e)
    }
  }
}
</script>